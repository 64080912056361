exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-admin-logout-js": () => import("./../../../src/pages/admin/logout.js" /* webpackChunkName: "component---src-pages-admin-logout-js" */),
  "component---src-pages-admin-profile-js": () => import("./../../../src/pages/admin/profile.js" /* webpackChunkName: "component---src-pages-admin-profile-js" */),
  "component---src-pages-chi-siamo-it-js": () => import("./../../../src/pages/chi-siamo.it.js" /* webpackChunkName: "component---src-pages-chi-siamo-it-js" */),
  "component---src-pages-contattaci-it-js": () => import("./../../../src/pages/contattaci.it.js" /* webpackChunkName: "component---src-pages-contattaci-it-js" */),
  "component---src-pages-elastic-it-js": () => import("./../../../src/pages/elastic.it.js" /* webpackChunkName: "component---src-pages-elastic-it-js" */),
  "component---src-pages-glossario-it-js": () => import("./../../../src/pages/glossario.it.js" /* webpackChunkName: "component---src-pages-glossario-it-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-it-js": () => import("./../../../src/pages/lavora-con-noi.it.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-it-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-it-js": () => import("./../../../src/pages/news.it.js" /* webpackChunkName: "component---src-pages-news-it-js" */),
  "component---src-pages-portfolio-it-js": () => import("./../../../src/pages/portfolio.it.js" /* webpackChunkName: "component---src-pages-portfolio-it-js" */),
  "component---src-pages-servizi-it-js": () => import("./../../../src/pages/servizi.it.js" /* webpackChunkName: "component---src-pages-servizi-it-js" */),
  "component---src-pages-techbrand-it-js": () => import("./../../../src/pages/techbrand.it.js" /* webpackChunkName: "component---src-pages-techbrand-it-js" */),
  "component---src-pages-tecnologie-it-js": () => import("./../../../src/pages/tecnologie.it.js" /* webpackChunkName: "component---src-pages-tecnologie-it-js" */),
  "component---src-pages-web-3-d-object-it-js": () => import("./../../../src/pages/web-3d-object.it.js" /* webpackChunkName: "component---src-pages-web-3-d-object-it-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-techbrand-js": () => import("./../../../src/templates/techbrand.js" /* webpackChunkName: "component---src-templates-techbrand-js" */),
  "component---src-templates-technology-js": () => import("./../../../src/templates/technology.js" /* webpackChunkName: "component---src-templates-technology-js" */)
}

